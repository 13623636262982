<template>
  <div class="dictionaryData fadeInRight">
    <div class="title-box clearfix">
      <span class="m-title">字典数据</span>
      <el-form
        inline
        ref="dicSettingForm"
        v-model="dicSettingData"
        class="right themed-form search-form"
        style="height: 40px; line-height: 40px"
      >
        <el-form-item label="字典组">
          <el-select v-model="dicSettingData.dictGroup" size="small">
            <el-option
              v-for="(item, index) in typeOptions"
              :key="index"
              :label="item.dictName"
              :value="item.dictName"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="dicSettingData.dictKey"
            placeholder="字典标签"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="dicSettingData.dictValue"
            placeholder="字典键值"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            class="themed-button"
            @click="onSearch"
            >查询</el-button
          >
        </el-form-item>

        <el-form-item>
          <el-button
            icon="el-icon-circle-plus"
            class="themed-button"
            type="info"
            @click="add()"
            >新增字典数据</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="content-box">
      <el-table
        border
        stripe
        ref="table"
        :max-height="maxTableHeight"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          :show-overflow-tooltip="true"
          prop="groupName"
          label="字典名称"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="dictKey"
          label="字典标签"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="dictValue"
          label="字典键值"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="sort"
          label="字典排序"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="status"
          label="状态"
          min-width="150"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.status == '1'">正常</span>
            <span v-else>停用</span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="remark"
          label="备注"
          min-width="150"
        >
        </el-table-column>

        <el-table-column
          :show-overflow-tooltip="true"
          prop="createTime"
          label="创建时间"
          min-width="150"
        >
        </el-table-column>
        <el-table-column label="操作" min-width="150">
          <template slot-scope="scope">
            <el-button
              size="mini"
              class="themed-button"
              type="info"
              @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="danger"
              class="themed-button"
              type="info"
              @click="deleteDict(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        :current-page.sync="pageNo"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
    </div>
    <!-- 添加或修改参数配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="字典名称" prop="groupName">
          <el-input v-model="form.groupName" placeholder="请输入字典名称" />
        </el-form-item>
        <el-form-item label="字典标签" prop="dictKey">
          <el-input v-model="form.dictKey" placeholder="请输入字典标签" />
        </el-form-item>
        <el-form-item label="字典键值" prop="dictValue">
          <el-input v-model="form.dictValue" placeholder="请输入字典键值" />
        </el-form-item>
        <el-form-item label="字典排序" prop="sort">
          <el-input v-model="form.sort" placeholder="请输入字典排序" />
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio
              v-for="dict in statusOptions"
              :key="dict.dictValue"
              :label="dict.dictValue"
              >{{ dict.dictLabel }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="form.remark"
            type="textarea"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import resize from "@/mixins/resize.js";
import {
  getDictManageList,
  delDictManageList,
  addDictManageList,
  editDictManageList,
  getDictGroup,
} from "@/api/systemManagement.js";
import { messageBox } from "@/utils/common.js";
export default {
  name: "dictionaryData",
  mixins: [resize],
  data() {
    return {
      dicSettingData: {
        dictKey: "",
        dictValue: "",
        dictGroup: "",
      },
      pageNo: 1,
      pageSize: 15,
      tableData: [],
      total: 0,
      title: "",
      open: false,
      statusOptions: [
        { dictLabel: "正常", dictValue: "1" },
        { dictLabel: "停用", dictValue: "0" },
      ],
      form: {
        groupName:"",
        dictKey: "",
        dictValue: "",
        status: "1",
        sort: "",
        remark: "",
        type: "1",
      },
      rules: {
         groupName: [
          { required: true, message: "数据名称不能为空", trigger: "blur" },
        ],
        dictKey: [
          { required: true, message: "数据标签不能为空", trigger: "blur" },
        ],
        dictValue: [
          { required: true, message: "数据键值不能为空", trigger: "blur" },
        ],
        sort: [
          { required: true, message: "数据顺序不能为空", trigger: "blur" },
        ],
      },
      typeOptions: [],
    };
  },
  created() {
    let dictGroup = this.$route.query.dictGroup; //当前的字典组
    this.getDictGroup(); //获取字典组数据
    //当前字典组赋值操作
    this.dicSettingData.dictGroup = dictGroup
    console.log(this.dicSettingData)
    this.getDictManageList(); //获取字典数据列表
  },
  methods: {
    getDictGroup() {
      getDictGroup().then((res) => {
        console.log(res);
        if(res){
           res.forEach(item=>{
             this.typeOptions.push({dictName:item.dictGroup,groupName:item.groupName})
           })
        }
      });
    },

    reset() {
      this.form = {
         groupName:undefined,
        id: undefined,
        dictKey: undefined,
        dictValue: undefined,
        status: "1",
        sort: undefined,
        remark: undefined,
        type: "1",
      }
      if (this.$refs["form"]) {
		this.$refs["form"].resetFields();
      }
    },
    handleEdit(row) {
      this.reset();
       this.form.groupName = row.groupName;
      this.form.id = row.id;
      this.form.dictKey = row.dictKey;
      this.form.dictValue = row.dictValue;
      this.form.status = row.status;
      this.form.remark = row.remark;
      this.form.sort = row.sort;
      this.open = true;
      this.title = "修改字典组数组";
    },
    cancel() {
      this.open = false;
      this.reset();
    },
    add() {
      //添加字典
      this.reset();
      this.open = true;
      this.title = "新增字典组数组";
    },
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id != undefined) {
            let params = {
              id: this.form.id,
             groupName: this.form.groupName,
              dictKey: this.form.dictKey,
              dictValue: this.form.dictValue,
              status: this.form.status,
              remark: this.form.remark,
              sort: this.form.sort,
              type: this.form.type,
              dictGroup: this.dicSettingData.dictGroup,
            };
            editDictManageList(params).then((res) => {
              messageBox(this, "修改成功");
              this.open = false;
              this.getDictManageList();
            });
          } else {
            let params = {
               groupName: this.form.groupName,
              dictKey: this.form.dictKey,
              dictValue: this.form.dictValue,
              status: this.form.status,
              remark: this.form.remark,
              sort: this.form.sort,
              type: this.form.type,
              dictGroup: this.dicSettingData.dictGroup,
            };
            addDictManageList(params).then((res) => {
              messageBox(this, "新增成功");
              this.open = false;
              this.getDictManageList();
            });
          }
        }
      });
    },
    getDictManageList() {
      let params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        type: "1",
      };
      Object.assign(params, this.dicSettingData);
      getDictManageList(params).then((res) => {
        this.tableData = res.rows;
        this.total = res.count;
      });
    },
    onSearch() {
      this.pageNo = 1;
      this.getDictManageList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getDictManageList();
    },
    onPageChange(page) {
      this.pageNo = page;
      this.getDictManageList();
    },
    deleteDict(id) {
      this.$confirm('是否确认删除字典编号为"' + id + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delDictManageList({ id }).then((res) => {
          this.getDictManageList();
          this.$message({
            type: "success",
            message: "删除成功",
          });
        });
      });
    },
  },
};
</script>
<style>
.dictionaryData .el-form-item__label {
  vertical-align: top;
}

</style>
